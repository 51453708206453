li {
    list-style-type: none;
    position: relative;    /* It's needed for setting position to absolute in the next rule. */
}

li::before {
    content: '•';
    color:#F5F5F5;
    opacity: 1;
    position: absolute;
    left: -0.9em;          /* Adjust this value so that it appears where you want. */
    font-size: 2em;      /* Adjust this value so that it appears what size you want. */
}