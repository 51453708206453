@tailwind base;
@tailwind components;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:wght@400;500&display=swap');

@tailwind utilities;

html {
    padding: env(safe-area-inset);
    background-color: #0A0A0A;
}

body {
    overflow: hidden;
    overflow-y: auto;
    background-color: #0A0A0A;
}